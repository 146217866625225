import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { WalletProviderContext, WalletConnectButton, WalletSignMessageButton, WalletCheckNFTButton} from '../components/WalletConnectButton';
// import { Workspace } from '../components/Workspace';



const nft2check = [
  "69Q54jutFoPLMNf6MxgHJWpQnYAvz6A1mVfEUUhjwvSp",
  "E29nMLTkR631NqiM8iynKHb9whtxjtQ8cB97eDF3EsEm",
  "BmLXAKhSGPChsmsdEbXzFDT8inkWZEzRP4NAeqd9pDEh",
  "BzGSaTZAw65MGQ5nb48mA8g7BE9z9KnXKbGgFgREHnyR",
  "J2f6eCV1NGF3Bes4TbanwqJvVkGuGVKN5huzvjsUSbbn",
  "Go9XRWFkh1Tct2dEB9We3bJzoitVi15un5MJipXmHRuo",
  "DM8K5oShoQKFp5vkeQtdQybwwXRxWkNRPMhjzecF6AVE",
  "4wU4UJYNANRnns2xckDNetY8WzW6nKJMUwfCpoZWJACt",
  "J2uarXFR3XVmKSDUpYzpiS8FTkHgaQBY9iMtof5m9cPH",
  "3wdvJznKPvP9mCna2BovzqmTfCA4pufxNjUNQSTEdrLr",
  "98Udgui2drmCFDnLr5AmMcNG1TMhAGVXi3XR4ZqyFvHr",
  "CRFpWEwEZ2P8DHCdtMfnUrvMaWF2u5ahKMLSwyy538Zu",
  "8SqhwLtKsvRGjHtuZ9EBSa2EiuMyubBkNx2Shn5U7c1u",
  "5Xntz9tFMiDwFLSUiErT6AkrE7Hdjm9hGhvWv5NQ8w38",
  "FhKUGZETCczGTfefiuCA8wZrefNazUECHVbmsYxTQwNc",
  "DPT94ZpwcPdpWPHc9y6faajmr4qqdahUbrALLtE97Tbq",
  "ECew2nSEGT2FV6LsBGJccYwDvoRNCvG4tALFkhDdsnQ1",
  "7vEpz3qqVoNhySDqfnoiaFhi5nzkPLpVWJeWVgcFcT8m",
  "B7oUKyBwGr6RVp3eaKnxS5gWZ5Nn6gtr5WHks9PdmruR",
  "JE2RJbN2uQDx8xcvSyXt9ppJzihCctLsP84wTneUmc9C",
  "9wddNkCv3TrtB1qQmp6E9zvyikLvH83WjKkqUPkWxkDF",
  "2DTxvMRgcmyBVBjrZsV1an9vPTPFfwGWcavnJ3AuufuJ",
  "BAGqYjpVffgwGp1V7g9jFxsw3SHSd39ji1fZRz1xusWr",
  "5mqWpFuR1JAfPr35cCGbtdFjmQnCjaPy4A23wo5KPu7K",
  "6z5DXMqQY3Gqa46rTvZBBCmYfu9oTx4yddbaQciTVRra",
  "5mvdEmMRMGrqTuccQKD9uY5XyZb4e9ZKdYCeJ6wu3hsZ",
  "3ABHqbL3Gxs7nR8uy5d7aMgXuMYvPvh2195YPwaRW7gu",
  "6usBaYy1e2pu81KitcfQNbeg1HdXcFqkqhmUarfqVxJm",
  "CpwCNYJmNBGDieYaNPNgX9ETBn2bZCwbN31h6QwWcGoe",
  "5fZGDnfULHhxfcGcHyRxhn2wSJ84VSs33NUjqqW8C1fT",
  "DJevNJ3FLPjmn6bd2ecGHd7RzJTwPs99JRpaiU5Fon11",
  "4jRQQ6dsV4gow84T72UezP4gLieRzPbqomy7JjKdYK2G",
  "Dhek89eYEuWBgcDHitUBs6Gckk43QoPXfg7G2VtZainp",
  "jsmxCuTKwgD5uDta1kk9kRpBReLNiTCAnW1FYydSxLE",
  "72nXPnATpnn3o3bsxYCnatYkPM1U1ZScGJsabrJqgteA",
  "4Az3btny9KaHFZMfrEwQZ3uB8TAwPvB72aqdyKb6N3UA",
  "3Vzm59afmjCnfLS37zjMd9na83H3EVt7KTFYZoMbTsWB",
  "JCabKW7gV879GdqWhLDmEA4pkQwz1ooUdnF1gbVLRbyC",
  "Ci6kr1ggGRDmmoDPmsww1uqAWjZtfZMS58vDVvytnrzD",
  "9jAZJvWaJTFKPUFhakCUYtqJMtiCN8xa18jrmWX5DRtM",
  "2bELcT4wkTY8abR7kVgr78zJFdfagtK2ZMRhJpEefSkK",
  "AgtywG5kXJAVv8L54FQk2H1WtYwwDKcAZFPtjCEMaLoT",
  "7L48xhSzwrx6NGtudK6zWMdjEezWfYd7Zt581RsxHkw",
  "JE5h9UTTeALuEvYp9sNVXKFfgWSJFvtCCMoK3kgvjmHw",
  "6h8xRV5R7MC8ewSkvE5KnHcYw7uz8dSSuPr1cRdYv3Hj",
  "GMauy91SxwdmRMy3ShZsixYjekS2VQDAQSghyG2pmPPo",
  "3pCxBHyZAEVPEftyo9zthFj8x1e2zngH8TZZv59bMm58",
  "AbXieqWoxgv3QwRrezcEfTxva3xKv49s4tnjEe7Z2MDj",
  "6yR1spz2zsPgExsMRXG6Wm42ZU8wjt9TSTbSws2FG4en",
  "ARjbc3SCHop5P8goYs24bf62K625ddqKur7uU2JVB47L",
  "HTSiqtWChr6fiUov2aUZ1D1mPUseAb2ajH3L49b1NJsS",
  "3C98ZLr8ibYTWeiqiW2vSpjJq5vSAdewiS2wmBcjcjBh",
  "Dq1qW8PShaAWgpQied2H7TAi9S7oHYDhuDFYQh8p2kNn",
  "2FNfwa36729pVri37TgsUq1Yt35rAut9gDGWmhYaHNbj",
  "Fzs4WtiMNrT2sX8p3jG3DVuTPDrggKZy1EgRoM3dLhDp",
  "2qSU27VN2rgJk8vncrf4rbwfC1KYgM7SxL7i6hLTfCPg",
  "BoAFkUubV8BHpGvMqbzxY7dYpn6WY5pcHBb7sFe5Y18y",
  "8N3eByUHVNGpXTz9xuHeZyGLCZTdZ8cii7rwca75fs8h",
  "C7zXxNnhUsuZXMDRp9gKDuM9hdf1HhSBDJex5Qsp8vMD",
  "Fw2KuhMb8C5iUTr2NohbHYCShdoVrJh42dQMt4PYmGgc",
  "AQVgfiqFdNP9QAmjHBi8hbvyhAtJjk1z9QyS5MYULqGx",
  "HsUt5RGN9zhxsQG58xQwVMhh6biAY2xELtXH2AraxDAM",
  "G6xrrDMWvUEaUzcqb7NerSCg2kREXxd2sqwTTdXrJgVW",
  "4J4EkWyBdpVFy2HGy2FfkrRRhxeoRzZZHhB8o9fu4714",
  "CKc2Zf9PtYZmWXb4qjMfkGtbJo8E7w3YRXt7zki8MQys",
  "FwbqinZRkmb3pNTvkWbDWxRCNk2Jms5Tgps8nxncfKXH",
  "6iWPdrHNW8bwRcjis9cDzTvnGMXW5nwezbvEgB6baAzk",
  "Ao9j2RiA3HhA1qVavoKCfFiTQzu7pP1TuhoMCGkzQVWT",
  "CAqJmFMjbuSsCyyx4GpidvhTHmp8XVFyU8UcX5T5rCtx",
  "6M6vwrffZHhDcr4ixx9TTCL5xF5MGF32PNYS74hT6NfC",
  "5Fkr2KMcsWR1XMu4PzxhanRMLVUfah1SaVpUrox9cuzS",
  "Be633bh6jxbpv91ushjaLgCp9TRjgBWHCgNKgzR52CVB",
  "2Nmj9SGXmw3cc4pn3L1z3CX4ubJVHQ7nR3nJJCUQafS7",
  "5mWw6hTyTNNnztGragFsuMWSe8CnAYDFTcdRXByoiyex",
  "GeXCEdWx6SL14t2wfpXX3BNuda2sp8HePsFQw8QDbJQ2",
  "8xQAXb6Kfk1iua3D2d9JsYHZn54rcykQhDbvZT6qvfLT",
  "GvbngKBfvoRmvqveFdYrbmTzzpQqC3qU6Q2Hoapo9oK2",
  "2ZjAhH1xLAYoH9jn1RR3hc6Cpk2e9otBHxmHrA7fZjq4",
  "BZtTQrH52o8W99kFqWmCxKLL5CZHnEgQ6SYB8MF4abKd",
  "8vxsx1jRYaLonJJWneUwdphBzeBhYwi4sdMZaTSDzYFM",
  "GwQ8yUU3NnGYp4fKuwuVVqrUKxsSZWqLxuuv7hn3DDUZ",
  "8DGEMFrjnRwCRgPpCiZNh8M13pT12XVPcuvqbeUqyZES",
  "GWkbdBo9JgQyVwJXp7uZgFPdiqN97HNVj1amuKCcB8Jp",
  "5tYuL2qdD4Q8EUJyUqxHtGcoKz7zsJHcD1LETG78yoji",
  "FVR7BUztxXGEp2gvn6c4vqsRbczA5CLxLbJRZTZs2u4k",
  "FZ8uewadnZnborfBHmXmZrVCQ3ykSdZmMoQrDQBS2v1G",
  "FscjdnRZjczJr3J3amPFLESwHbPLqutRK9eCirCe7e3U",
  "8ep36DcJYy4kbzKLB3S72f1fsstA9DWCQWHJZk1nMPZA",
  "5XqNtCwnT6wotg2BD7yPun5cDSG7N9hWxK4MttxkXwEL",
  "5wbsPjqh4XaQHBcmCQgXXvxXJ48MX5Fv8UfxR1wTdpkn",
  "6vzxu9c7EPxe2LngrwVG41GXH2SZRkwSYeriqCZAhiEm",
  "9mxg2n2yzwFTirFCr21inYxv5rc6EpPfHkrWCjEV2bqM",
  "CzmJQZ6DaAkpZLCH3FyBwrY7eXQbe9a5rNzrfTh33wRt",
  "Hq3oE4YfhwNaZFhX4DLvftVDg9FR3fA5zLxQ85NLV54v",
  "7UtFB79SMMkWPXMPsNZfbegBXWVfuwJhQhp83bMGW4r7",
  "9ir19Ts7APfvbeWkS3R1iAqNkhbQh48hTC3aoZZ7s1aW",
  "8zV7ktXh4eREri1AqFsGpBoYd2vZKsU985bNBWLXApjD",
  "BGBwvom2EESvnAdMfn7G1ZoFke8ocuakYMYkYHEQT1ET",
  "8aYGndBfpjL7p3s9jj5rWZNBR5DmeYosW8y2StNfToHZ",
  "DMjYZAohm8pKsyAGMYss88a3bXegzcbXsKdWH89WaZzv",
  "3qiSYGvPiYZUnvsJaVsn3jD6JtYxbfXH4waYepeTKrVp",
  "AJUkAiTSYWF45nmWXfPCQRtRwdVsU5U8qnQaJmTaXVMo",
  "8sGXpEoyD48BVQhL1CN1zDE8mXMPwRvEMqS8uf177PMj",
  "EMkjQW21CJFDHFTCmnNA4BKWgYSqvHShFWEcSSLFpVSH",
  "9aW6QWYdnt6Xe5BjA5ZxkuBJFJAGyyAZ4dpomhyKtJoT",
  "CQt6CxbhZWq1LU76AxhJC15Gt7gBLsvtp4wB1vWagFA3",
  "2f7xWxrxaxUnCCC5qVt8mqzEqgUwQ5HcMXDxvyUxWvwH",
  "DG1u5fUtksLR7Eyp9gGf7LTNJtA1ecS2WPUSFqCVH6P5",
  "CR83fDdW6HKoJ6PSeERAMQXV1s7akV9ySnYNAxP3Cp79",
  "7iNqrsPXVydBkh9mwT4gGDs2Vn3NcpcPjcTCcBXJfLpm",
  "9SgQyj5qFWnmpjx2TeusDNz2p8aouzwjLKwBXhBLL6Tv",
  "HmRzmLaLQN7gGbwH2dJnuDRek6nHfFTQVycY4sJyaLdB",
  "DDv19zW6MshjavgRPt57shXhqrCxSko3KbVmk12WZ8SY",
  "GY5xrgr7yYZKfF89u17tM3KRcZ7C5QKqVfFqgJmKDRPK",
  "8ngn6uE7JTkaGPeRLfh8DXAJiAdRUEiWtSUSLyXhXwdF",
  "FCBnFjsTSjDFX7fXTZxmHjcxqtH2Pa2h4VxiEeiQF8SP",
  "HbzsKSc8PSWj7ZQMsAJiRxgHiVwmrWoWcweKYXNED6Uk",
  "2VkkTNnRt5JJ2MhwBCn816H9rk6gVSP6SxCStiYAhU5U",
  "6ZYJ5SH5B9QmPZmPU9jzVydHmAfuFwBZgAGmi1UzqePC",
  "54GMCpWEWbZQ2hxH8ckZZAStc2mu6ZrQ3sW9An6US44E",
  "8e122MNJWow5w9j2AEPvDMCt9JxsQhPL1PdABGLxjfSW",
  "3m6xbQuJ8Tdscg7bMrxEFSWANoiKW1uE7dbHUpdxPjVZ",
  "3k5VJD1BiUdnHLJ5HVZusZCepRddwA7jH19aEGpLD7kY",
  "8U9LLuGc9RKEysFyLTMbFGSM5NDTn3XqKovyjxuUXLZe",
  "DCSXC7zK4tTCP2gjFiPn2vhLoFCbU17M4TYPXzbZAm4D",
  "8TYAj1ETPcMbHotnUeVHXRBRgenq4zHQLzG3DZ58xvbp",
  "Ht5rAntnHNiFMa6qxvHTjB9rJb7asujRxDVyBBTBPpY8",
  "DULLGWL48Cjy5DFtkFtJkiZMzTj2dEpQjiQprtwBxHpU",
  "9aMEhDfjm3GXnKtTskNCW7YmrH4wKCo7uM8FNX3NtJPR",
  "HFMCPo59XREBwzpmnQHFG8x4TzjeXUbeigaUEMKEsdFT",
  "HCkv7KbXfHboLDAjHkGFRGkEvLDKGjif2HSLLEotgAQ8",
  "2AGAQZLG4ueuYAGWUuAiXq86dY4dmXGeJexJjmkSt17S",
  "GX41xRD9mctMgNqaeQtWEXqtQBi5H1cqTHFusbEHawQQ",
  "9Uehe29gwsk3uarj4wgsqw7zTEvHrcqLx8mxjVwm5vnW",
  "1hPZhDnVG4NvKnJac7JjfXW5mNu5d1jvFQTrPpMFFjL",
  "7JYpw9qhmfeQAmxFCkZJDnkXrwrZSWpipnvQJBZnhSCE",
  "A4NK73DgqM9o2agkaz2jqaHWCSLBFfhKCrERKTmU3f1X",
  "8qfMxWMjwhEJdAKnW4z9DYg6U9WAorvcpbSoQbmQQacH",
  "2tPScsPx6sPv67CaU1JZM55miPBZ3QFjnTw4PSeBp8Fx",
  "F53T2eXTE9xuq3h16jLqNqCPiEjT8VKgwgwwGpXpDsWz",
  "7AnWpwUaMZ3A95dopirp4EtfKaDhe1BXA6MZaKZXxkVv",
  "CUSwYiAAN1xA6nNyTgFWo4akfHsRnKpoPiGbkc5NesoA",
  "HdczK473UPzAfmiCg7bjumH5c3TAXGMjPSY31Sf7MaHT",
  "5oHbdt9LyxxEsFxQT711CwQYSVrYDVhAygHHjDJa5p47",
  "EeSaP6SGFZj5UXGT28sq61JgD5KxPuSACfS2dvq1Luhj"
]

// styles
// TODO

// markup
const ValidatorPage = () => {

  return (
    <>
      <ToastContainer />
      <WalletProviderContext>
        <title>IRON POC</title>
        <h1>IRON NFT validator Page</h1>
        <h3>Wallet Connect test</h3>
        <WalletConnectButton />

        <h3>Wallet Signature request test</h3>
        <WalletSignMessageButton />

        <h3>Wallet Check NFT test</h3>
        <WalletCheckNFTButton nft2check={nft2check} />
      </WalletProviderContext>
    </>
  )
}

export default ValidatorPage
